// Module
var code = `<% if (isCommsEnabled) { %>
  <form class="js-community-type-toggle community-type-toggle">

    <% if (isDistributedAdminEnabled) { %>

      <label for="business-unit"><%- t('general.businessUnit') %></label>
      <%= axSelect({ id: 'business-unit' , data: { field: 'businessUnitId' , options: 'businessUnits' , shallow: 'true'
        }, disabled: disabled, value: selectedBusinessUnit, noFormEditorDefault: 'true' , 'aria-label' :
        t('general.businessUnit'), 'aria-describedby' : 'business-unit-helper' }) %>
        <div id="business-unit-helper" class="ax-form-helper-text ax-font--secondary ax-font__caption"
          style="margin-top: var(--ax-spacing-xs)"><%- t('communitiesManagement.accessSettings.businessUnit.helpText')
            %>
        </div>
        <% } %>

          <p style="margin-top: var(--ax-spacing-xl)"><%- t('communitiesManagement.community.communityTypeQuestion') %>
          </p>
          <div class="ax-grid ax-grid__col--12">
            <ul id="community-types" class="ax-grid__col--3" data-field="communityAccessType" data-editor="RadioGroup"
              data-options="communityAccessTypes">
            </ul>
            <div class="ax-grid__col--6">
              <p id="group-type-extra-info" class="ax-font--secondary" aria-hidden="true">
                <%- t('communitiesManagement.community.groupTypeGrantAccess') %>
              </p>
              <p id="team-type-extra-info" class="ax-font--secondary" aria-hidden="true">
                <%- t('communitiesManagement.community.teamTypeGrantAccess') %>
              </p>
            </div>
          </div>
  </form>
  <hr aria-hidden="true">
  <% } %>

    <div class="js-table-region"></div>
`;
// Exports
module.exports = code;